import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import detectEthereumProvider from '@metamask/detect-provider';
import pancakeSwapRouterABI from './pancakeSwapRouterABI.json';
import ERC20ABI from './ERC20ABI.json';
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import { Container, Row, Col } from 'react-bootstrap';
import "./App.css";
import Modals from "./components/modals";
import axios from 'axios';


function App() {

  const [currentAccount, setCurrentAccount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [direction, setDirection] = useState(false);
  const [tokenContract, setTokenContract] = useState(null);
  const [tokenPrice, setTokenPrice] = useState(0);
  const [tokenPriceInBNB, setTokenPriceInBNB] = useState(0);
  const [tokenAmount, setTokenAmount] = useState(0);
  const [bnbAmount, setBnbAmount] = useState(0);
  const [tokenSymbol, setTokenSymbol] = useState(null);
  const [tokenDecimals, setTokenDecimals] = useState(18);
  const [title, setTitle] = useState(null);
  const [swapButtonText, setSwapButtonText] = useState("Swap");
  //const [bnbBalance, setBnbBalance] = useState(null);
  //const [tokenBalance, setTokenBalance] = useState(null);

  // Connect to the Binance Smart Chain (BSC)
  const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed.binance.org/');
  const zxfRouter = "0x95f0B924993a210C7AD5CE7F69Af94FB6F99326F";
  const routerAddress = '0x10ED43C718714eb63d5aA57B78B54704E256024E';
  const bnbAddress = '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c';
  const usdtAddress ='0x55d398326f99059fF775485246999027B3197955';


  // Create a contract instance for the PancakeSwap Router
  const router = new ethers.Contract(routerAddress, pancakeSwapRouterABI, provider);
  
  const handleBnbAmountChange = (event) => {
    setBnbAmount(event.target.value);

    if(Number(event.target.value) >= 0.0025){
      // Call the getAmountsOut function to get the price of your token in BNB
      router.getAmountsOut(ethers.utils.parseUnits(String(event.target.value - 0.0005), 18), [bnbAddress, tokenContract])
      .then((result) => {
        const outAmount = ethers.utils.formatUnits(result[1], 18);
        //alert(outAmount);
        setTokenAmount(outAmount);
      })
      .catch((error) => {
        console.error('Error fetching token price:', error);
      });
    }else{
      setTokenAmount(0);
    }
    //setTokenAmount(event.target.value / tokenPriceInBNB);
  };

  const handleTokenAmountChange = (event) => {
    setTokenAmount(event.target.value);
    if(Number(event.target.value) > 0){
      // Call the getAmountsOut function to get the price of your token in BNB
      router.getAmountsOut(ethers.utils.parseUnits(String(event.target.value), tokenDecimals), [tokenContract, bnbAddress])
      .then((result) => {
        const outAmount = ethers.utils.formatUnits(result[1], tokenDecimals);
        //alert(outAmount);
        if(outAmount <= 0.0005){
          setBnbAmount(0);
        }else{
          setBnbAmount(outAmount - 0.0005);
        }
      })
      .catch((error) => {
        console.error('Error fetching token price:', error);
      });
    }
    //setBnbAmount(tokenAmount * tokenPriceInBNB)
  };

  const fetchData = async () => {
    try {
      const response = await axios.get('data.json');
      setTokenContract(response.data[0].value);
      setTokenSymbol(response.data[1].value);
      setTokenDecimals(response.data[2].value);
      setTitle(response.data[3].value);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    checkWalletIsConnected();
    fetchData();

    // Call the getAmountsOut function to get the price of your token in BNB
    router.getAmountsOut(ethers.utils.parseUnits('1', tokenDecimals), [tokenContract, bnbAddress])
      .then((result) => {
        const tokenPriceInBNB = ethers.utils.formatUnits(result[1], tokenDecimals);
        setTokenPriceInBNB(tokenPriceInBNB);
      })
      .catch((error) => {
        console.error('Error fetching token price:', error);
      });

    // Call the getAmountsOut function to get the price of your token in BNB
    router.getAmountsOut(ethers.utils.parseUnits('1', 18), [bnbAddress, usdtAddress])
    .then((result) => {
      const tokenPriceInUSDT = ethers.utils.formatUnits(result[1], 18);
      const finalPrice = tokenPriceInBNB * tokenPriceInUSDT;
      setTokenPrice(finalPrice.toFixed(10));
    })
    .catch((error) => {
      console.error('Error fetching token price:', error);
    });

  }, [tokenPriceInBNB, tokenContract]);

  const handelDirection = () => {
    setDirection(!direction);
    setTokenAmount(0);
    setBnbAmount(0);
  }

  async function addToken() {
    try {
      const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Currently only supports ERC20 tokens
          options: {
            address: tokenContract, // The token's contract address
            symbol: tokenSymbol,   // A string symbol of the token
            decimals: tokenDecimals,    // The number of decimals the token uses
            image: window.location.href + '/img/token-logo.png',  // A string url of the token logo
          },
        },
      });
  
      if (wasAdded) {
        console.log('Token was added successfully!');
      } else {
        console.log('Token was not added.');
      }
    } catch (error) {
      console.error(error);
    }
  }

  const bscUrl = 'https://bscscan.com/token/' + tokenContract;

  function createMarkup() {
    return {__html: `<iframe width="100%" height="430"  src="https://coinbrain.com/embed/bnb-${tokenContract}?theme=dark&padding=4&chart=1&trades=0"></iframe>`};
  }

  const checkWalletIsConnected = async () => {
    const detectedProvider = await detectEthereumProvider();
    if(!detectedProvider){
      console.log("Metamask NOT Installed");
      return;
    }else{
      console.log("Metamask Installed");
    }
   }

  const connectWalletHandler = async() => { 
    const detectedProvider = await detectEthereumProvider();
    if(!detectedProvider){
      alert("Please Install Metamask!");
    }

    try{
      const accounts = await detectedProvider.request({method: 'eth_requestAccounts'});
      console.log("Found an account :", accounts[0]);
      setCurrentAccount(accounts[0]);
    }catch (err){
      console.log(err);
    }
  }

  const swapTokens = async() => {
    if (bnbAmount < 0.0025 && !direction) {
      alert("Enter at least 0.0025 BNB");
      return;
    }

    const detectedProvider = await detectEthereumProvider();
    const chainId = await detectedProvider.request({ method: 'eth_chainId' });
    if(parseInt(chainId, 16) !== 56){
      alert("Please Switch MetaMask to BNB Smart Chain");
      await detectedProvider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x38' }],
      });
      return;
    };

      if(detectedProvider){
        const provider = new ethers.providers.Web3Provider(detectedProvider);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(zxfRouter, pancakeSwapRouterABI, signer);
        const tokenContractApproval = new ethers.Contract(tokenContract, ERC20ABI, signer);

        const amountOutMin = 0; // Set your minimum amount out, usually you'll calculate this from the current rates
        const path = [bnbAddress, tokenContract]; // WETH address is zero address for BNB
        const path2 = [tokenContract, bnbAddress];
        const to = currentAccount;
        const deadline = Math.floor(Date.now() / 1000) + 60 * 20; // 20 minutes from now

        if(!direction){
          // Get the BNB balance
          const balance = await provider.getBalance(currentAccount);
          if(ethers.utils.formatEther(balance) < bnbAmount){
            alert('Not enough BNB balance');
            return;
          };
          try {
            const tx = await contract.swapExactETHForTokens(
              amountOutMin,
              path,
              to,
              deadline,
              {
                value: ethers.utils.parseEther(bnbAmount),
              }
            );
            console.log('Transaction sent:', tx);
            setSwapButtonText('Please Wait...');
            setIsLoading(true);
            await tx.wait();
            console.log('Transaction confirmed:', tx);
            setSwapButtonText('Swap');
            setIsLoading(false);
            alert('Congratulations, Swap transaction confirmed');
          } catch (error) {
            setSwapButtonText('Swap');
            setIsLoading(false);
            console.error('Swap failed', error);
            alert('Swap failed');
          }
        }else{
          // Get the token balance
          const contractBal = new ethers.Contract(tokenContract, ERC20ABI, provider);
          const tokenBal = await contractBal.balanceOf(currentAccount);
          //alert(ethers.utils.formatUnits(tokenBal, tokenDecimals));
          if(ethers.utils.formatUnits(tokenBal, tokenDecimals) < tokenAmount){
            alert('Not enough token balance');
            return;
          };
          try {
            // Approve the router to spend tokens
            const approvalTx = await tokenContractApproval.approve(zxfRouter, ethers.utils.parseEther(tokenAmount));
            setSwapButtonText('Please Wait...');
            setIsLoading(true);
            await approvalTx.wait();
            console.log('Approval confirmed');
            //alert("approval confirmed");

            // Manually estimate gas limit
            const estimatedGasLimit = await contract.estimateGas.swapExactTokensForETH(
              ethers.utils.parseUnits(tokenAmount, tokenDecimals),
              amountOutMin,
              path2,
              to,
              deadline
            );

            // Swap tokens for BNB
            const tx = await contract.swapExactTokensForETH(
              ethers.utils.parseUnits(tokenAmount, tokenDecimals),
              amountOutMin,
              path2,
              to,
              deadline,
              {
                gasLimit: estimatedGasLimit.mul(2), // Multiply by a safety margin
              }
            );
            setSwapButtonText('Please Wait...');
            setIsLoading(true);
            console.log('Transaction sent:', tx);
            await tx.wait();
            console.log('Transaction confirmed:', tx);
            setSwapButtonText('Swap');
            setIsLoading(false);
            alert('Congratulations, Swap transaction confirmed');
          } catch (error) {
            setSwapButtonText('Swap');
            console.error('Swap failed', error);
            setIsLoading(false);
            alert('Swap failed');
          }
        }

    }
  }

  const connectWalletButton = () => {
    return (
      <button onClick={connectWalletHandler} className="curr-btn btn-connect">Connect Wallet</button>
    )
  }

  const swapButton = () => {
    return (
      <button onClick={swapTokens} className="curr-btn btn-connect">{swapButtonText}</button>
    )
  }

  return (
    <>
      <nav className="navbar navbar-expand-sm flex-sm-nowrap flex-wrap">
        <div className="container-fluid">
          <button
            className="navbar-toggler flex-grow-sm-1 flex-grow-0 me-2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar5"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <span className="navbar-brand">
            <a href="/#">
              <img src="img/logo.png" height={50} width={200} alt="Add to MetaMask"/>
            </a>
          </span>

          <div className="navbar-collapse collapse" id="navbar5">

            <div className="d-flex w-100 justify-content-end">
              <div className="border-box-s d-flex flex-row align-item-center py-2">
                <div className="border-box-s d-flex">

                        <div className="textfirst">
                          <div className="d-flex gap-2 align-item-center text-white">
                            <img
                              src="./img/bnb-logo.svg"
                              alt=""
                              width="20"
                              height="20"
                            />
                            <div>BNB Smart Chain</div>
                          </div>
                        </div>

                    
                </div>
              </div>
            </div>
          </div>

        </div>
      </nav>

      <Container className="mt-5">
        <Row>
          <Col md={6} className="justify-content-center mt-5">
            <dive>
              <div style={{width: "100%"}} dangerouslySetInnerHTML={createMarkup()} />;
            </dive>
          </Col>
            <Col md={6}>
              <div className="card-st">
                <div className="card-d">
                
                    <main className="card-d-bg">
                      <div className="border-boxing flex-adjust-d space-s-between ma-color px-2 pb-1">
                        <div className="d-flex gap-2">
                          <div className="s-color pad-link"><h4>{title}</h4></div>
                        </div>

                        <div className="border-boxing flex-adjust-d width-fit-d justify-content-end">
                          <div className="position-relative">
                            <div className="dropdown">
                              <button
                                className="btn border-unset-d p-0"
                                type="button"
                                onClick={addToken}
                              >
                                <div className="border-boxing flex-adjust-d svg-pad pe-0">
                                  <img src="img/metamask.png" height={24} width={24} alt="Add to MetaMask"/>
                                </div>
                              </button>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{display:'flex', flexDirection: `${direction ? 'column-reverse' : 'column'}`}}>
                        <div className="swap-card" id="from_token_select">
                          <div className="flex-wrap-d">
                            <div className="input-contain">
                              <div className="flex-ne">
                                <input
                                  className="input-swap-d input-d-swp"
                                  type="text"
                                  placeholder="0"
                                  value={direction? parseFloat(bnbAmount).toFixed(6) : bnbAmount} 
                                  onChange={handleBnbAmountChange}
                                />
                                <div className="border-boxing flex-adjust-d width-fit-d">
                                  <div className="position-relative d-flex me-1">
                                    <img src="img/coin.png" height={32} width={32} alt="Add to MetaMask"/>
                                  </div>
                                  <span className="currency-text">BNB</span>
                                </div>
                              </div>
                              <div className="amount-div amt-d">
                                <div className="border-boxing flex-adjust-d space-s-between">
                                  <span></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="arrow-div" onClick={handelDirection}>
                          <button
                            // onClick={this.switchTokens}
                            id="swap_token_address"
                            className="arrow-swap"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#5D6785"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <line x1="12" y1="5" x2="12" y2="19"></line>
                              <polyline points="19 12 12 19 5 12"></polyline>
                            </svg>
                          </button>
                        </div>

                        <div className="swap-card" id="to_token_select">
                            <div className="flex-wrap-d">
                              <div className="input-contain">
                                <div className="flex-ne">
                                  <input
                                    className="input-swap-d input-d-swp"
                                    placeholder="0"
                                    value={direction? tokenAmount : parseFloat(tokenAmount).toFixed(4)} 
                                    onChange={handleTokenAmountChange}
                                  />
                                  <div className="border-boxing flex-adjust-d width-fit-d">
                                  <div className="position-relative d-flex me-1">
                                  <a href={bscUrl} target='_blank' rel="noreferrer"><img src="img/token-logo.png" height={32} width={32} alt="Add to MetaMask"/></a>
                                    </div>
                                    <span className="currency-text">{tokenSymbol}</span>
                                  </div>
                                </div>
                                <div className="amount-div amt-d">
                                  <div className="border-boxing flex-adjust-d space-s-between">
                                    <div className=" "></div>
                                    <span></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                      <div className="input-div-s">
                        <div>
                          
                        </div>

                        <div className="py-2">
                          <div className="accordion accordion-st" id="details">
                            <div className="accordion-item accordion-itm">
                              <h2 className="accordion-header">
                                <button
                                  className="accordion-button collapsed text-white accord-btn"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseTwo"
                                  aria-expanded="false"
                                  aria-controls="collapseTwo"
                                >
                                  {tokenSymbol} Price: ${tokenPrice}
                                </button>
                              </h2>
                              <div
                                id="collapseTwo"
                                className="accordion-collapse collapse"
                                data-bs-parent="#details"
                              >
                                <div className="accordion-body pt-0">
                                  <div className="d-flex py-2 justify-space-between">
                                    <p className="m-0 text-white">Rate:</p>
                                    <p className="m-0 text-grey">1 BNB = {(1 / tokenPriceInBNB).toFixed(5)} {tokenSymbol}</p>
                                  </div>
                                  <div className="d-flex py-2 justify-space-between">
                                    <p className="m-0 text-white">Minimum Swap:</p>
                                    <p className="m-0 text-grey">0.0025 BNB</p>
                                  </div>
                                  <hr className="my-1 border-s-white" />

                                  <div className="d-flex py-2 justify-space-between">
                                    <p className="m-0 text-white">Swap Fee</p>
                                    <p className="m-0 text-grey">0.0005 BNB</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          {currentAccount ? swapButton() : connectWalletButton()}
                        </div>
                      </div>
                    </main> 
                        
                </div>
              </div>
            </Col>
          </Row>
        </Container>

      <Modals />
    </>
  );
}

export default App;
